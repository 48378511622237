
body {
   background-color: #eaeaea !important;
   font-family: 'Montserrat', sans-serif !important;
   font-size: 20px;
}
.react-calendar {
	font-family: 'Montserrat', sans-serif !important;
}
.epione {
	display: flex;
	justify-content: center;
}
.epi_container {
	min-height: 500px;
	display: flex;
	flex-direction: column;
	max-width: 400px;
	align-items: center;
    position: relative;
}
.img_container img {
	border-radius: 50%;
	border: 15px solid #fff;
}
.react-calendar__tile--now {
	font-weight: none !important;
	color: #000 !important;
	/* border-bottom: 2px solid #F2B19D !important; */
}
.react-calendar__tile--now abbr {
	border-bottom: 3px solid #F2B19D !important;
}		
.epi_heading {
	color: #80A0AB;
	font-size: 22px;
	text-align: center;
	font-weight: 700;
	line-height: 35px;
  text-transform: uppercase;
}
.epi_btn_sec {
	display: flex;
	justify-content: space-between;
}
.epi_btn {
	background: #6b919e;
	text-decoration: none;
	display: block;
	color: #fff;
	padding: 14px 25px;
	font-weight: bold;
	text-transform: uppercase;
	letter-spacing: 1px;
	border-radius: 3px;
	cursor: pointer;
	min-width: 154px;
	text-align: center;
	margin: 0px 10px;
}
@media(max-width:450px){
	.epi_heading{
		font-size:18px;
		line-height: 30px;
	}
	.epi_page_title {
		padding: 15px;
	}
}
@media(max-width: 379px){
 .epi_btn {
      min-width: 140px;
	  padding: 10px;
 }
}
.react-calendar__tile.react-calendar__month-view__days__day {
	border-radius: 50%;
	background-color: #5B7F8C;
	padding: 15px;
	color: #fff;
}
.react-calendar__month-view__weekdays__weekday {
	font-size: 16px;
	border-bottom: none !important;
	color: #AAAAAA;
}
.react-calendar__month-view__days__day--weekend {
	color: #AAAAAA !important;
	background-color: transparent !important;
	text-decoration: none !important;

}

.react-calendar__tile--now {
	text-decoration: none !important;
  }
  .react-calendar__tile--active {
	text-decoration: none !important;
  }
.disabled-weekday {
	color: #0da2dc; /* Set the desired text color for disabled Saturdays and Sundays */
}
.react-calendar__tile--active {
	background-color: #5B7F8C !important;
	color : #fff !important;
	border-radius: 50% !important;
}	
.react-calendar__tile:enabled:hover, .react-calendar__tile:enabled:focus {
	background-color: none !important;
	border-radius: 50%;
}
.react-calendar__tile:disabled {
	background-color: transparent !important;
	color: #aaaaaa !important;
}
.react-calendar__tile:enabled:hover, .react-calendar__tile:enabled:focus {
	background-color: none !important;
	border-radius: 50%;
}
.react-calendar__tile:enabled:hover {
  background: #5B7F8C !important;
  color: #fff !important;
  border-radius: 50% !important	;
  border: 5px solid transparent !important;
	background-clip: padding-box !important;
}
.react-calendar__tile:enabled:focus {
  border-radius: 50% !important	;
  border: 5px solid transparent !important;
	background-clip: padding-box !important;
}
.epione_calendar .disabled-date:hover {
   background: #e0e0e0 !important;
   color: #5B7F8C  !important;
   border-radius: 50% !important	;
   border: 5px solid transparent !important;
	 background-clip: padding-box !important;
}
.react-calendar__navigation button {
	color: black !important;
	font-size: 16px;
}
.react-calendar__tile {
	line-height: 30px;
	text-decoration: none !important;
}

.PhoneInputInput {
	border: none !important;
	box-shadow: none !important;
	outline: none !important;
	background: transparent;
	border-bottom: 2px solid #ccc !important;
	padding: 7px;
}
.react-calendar{
	background: transparent !important;
}
.yes_no {
	min-width: 235px;
}
.logo {
	width: 300px;
	position: relative;
	margin-bottom: 40px;
	margin-top: 30px;
}	
.img_container {
	margin-bottom: 40px;
}	
.epi_title {
	margin-bottom: 40px;
}
.epi_back_btn {
	/* background: #fff;
	border-radius: 50%;
	width: 40px;
	height: 41px;
	display: flex;
	align-items: center;
	justify-content: center;
	text-decoration: none;
	color: #000;
	position: absolute;
	left: 0;
	bottom: -8px; */
	background: #fff;
	border-radius: 5px;
	width: 75px;
	height: 35px;
	display: flex;
	align-items: center;	
	justify-content: center;
	text-decoration: none;
	color: #000;
	position: absolute;
	left: 0;
	top: -4px;
	padding: 4px;
}
.great_to {
	margin-top: 60px;
}
.epi_input {
	border: none;
	background: none;
	width: 100%;
	margin-bottom: 15px;
	padding: 7px;
	border-bottom: 2px solid #ccc;
	outline: none;
}
.first_visit {
	margin-top: 100px;
}
.epi_page_title {
	font-size: 20px;
	text-align: center;
	font-weight: 500;
}
.epi_fields {
	max-width: 500px;
	width: 90%;
	margin: 0 auto 30px;
}
.epione_calendar{
	background-color: none !important;
	border: none !important;
	box-shadow: none !important;
}
.epi_lin {
	text-decoration: none;
	display: block;
	margin: 10px 15px;
	background: #e0e0e0;
	padding: 8px;
	text-align: center;
	font-size: 20px;
	font-weight: 500;
	color: #5B7F8C;
	border-radius: 4px;
	cursor: pointer;
}
.epi_enable:hover, .epi_lin_active {
	background-color: #5b7f8c;
	color: #fff;
}
.epi_btn:hover, .epi_btn_active {
	background: #4e6c77;
}
.epi_enable {
	background-color: #1976d2;
	color: #fff;
}
.react-calendar__navigation button:enabled:hover, .react-calendar__navigation button:enabled:focus {
	background-color: transparent !important;
}
.epi_ball {
	width: 25px;
	height: 25px;
	border-radius: 50%;
	cursor: pointer;
	display: inline-block;
	margin-right: 10px;
}






.react-calendar { 
	width: 400px;
	max-width: 100%;
	background-color: #fff;
	color: #222;
	border-radius: 8px;
	box-shadow: 0 12px 24px rgba(0, 0, 0, 0.2);
	font-family: Arial, Helvetica, sans-serif;
	line-height: 1.125em;
   }
   .react-calendar__navigation button {
	color: #6f48eb;
	min-width: 44px;
	background: none;
	font-size: 16px;
	margin-top: 8px;
   }
   .react-calendar__navigation button:enabled:hover,
   .react-calendar__navigation button:enabled:focus {
	background-color: #f8f8fa;
   }
   .react-calendar__navigation button[disabled] {
	background-color: #f0f0f0;
   }
   abbr[title] {
	text-decoration: none;
   }
   /* .react-calendar__month-view__days__day--weekend {
	color: #d10000;
   } */
   .react-calendar__tile:enabled:hover,
   .react-calendar__tile:enabled:focus {
	background: #f8f8fa;
	color: #6f48eb;
	border-radius: 6px;
   }
   .react-calendar__tile--now {
	background: #6f48eb33;
	border-radius: 6px;
	font-weight: bold;
	color: #6f48eb;
   }
   .react-calendar__tile--now:enabled:hover,
   .react-calendar__tile--now:enabled:focus {
	background: #6f48eb33;
	border-radius: 6px;
	font-weight: bold;
	color: #6f48eb;
   }
   .react-calendar__tile--hasActive:enabled:hover,
   .react-calendar__tile--hasActive:enabled:focus {
	background: #f8f8fa;
   }
   .react-calendar__tile--active {
	background: #6f48eb;
	border-radius: 6px;
	font-weight: bold;
	color: white;
   }
   .react-calendar__tile--active:enabled:hover,
   .react-calendar__tile--active:enabled:focus {
	background: #6f48eb;
	color: white;
   }
   .react-calendar--selectRange .react-calendar__tile--hover {
	background-color: #f8f8fa;
   }
   .react-calendar__tile--range {
	background: #f8f8fa;
	color: #6f48eb;
	border-radius: 0;
   }
   .react-calendar__tile--rangeStart {
	border-top-right-radius: 0;
	border-bottom-right-radius: 0;
	border-top-left-radius: 6px;
	border-bottom-left-radius: 6px;
	background: #6f48eb;
	color: white;
   }
   .react-calendar__tile--rangeEnd {
	border-top-left-radius: 0;
	border-bottom-left-radius: 0;
	border-top-right-radius: 6px;
	border-bottom-right-radius: 6px;
	background: #6f48eb;
	color: white;
   }
   .react-calendar__tile.react-calendar__month-view__days__day {
	background-color: #1976d2;
	color: #fff;
	font-weight: 500;
}
.react-calendar__tile:disabled {
	background-color: #EDF2F5;
}
.epione_calendar .disabled-date {
	background-color: #e0e0e0 !important;
	color: #5B7F8C !important;
	font-weight: 600 !important;
  }
/* hide change year buttons */
.epione_calendar .react-calendar__navigation__arrow.react-calendar__navigation__next2-button {
	display: none;
}
.epione_calendar .react-calendar__navigation__arrow.react-calendar__navigation__prev2-button {
	display: none;
}

.epi_date {
	flex: 0 0 60px !important; 
}
.react-calendar.epione_calendar {
	width: 420px !important;
}
.react-calendar__navigation button:disabled {
	visibility : hidden !important;
}
.react-calendar__navigation__label__labelText.react-calendar__navigation__label__labelText--from {
	font-weight: 600;
}
.react-calendar__navigation__arrow {
	font-size: 35px !important;
}
.epi_date {
	height: 60px !important;
	border: 5px solid transparent !important;
	background-clip: padding-box !important;
}
@media(max-width:420px){
	.react-calendar.epione_calendar {
		width: 320px !important;
		padding: 0 10px;
	}
	.epi_date {
		flex: 0 0 42.85px !important;
		height:45px !important;
		border: 3px solid transparent !important;
	}
	.react-calendar__tile.react-calendar__month-view__days__day{
		padding: 7px !important;
	}
}
@media(max-width:400px){
	.slots_block {
		flex-direction: column;
		align-items: center;
	}
	.slots_block_div{
		margin-bottom: 15px;
	}
}